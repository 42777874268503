.home-bg{
//     position: relative;
//   &::before{
//     position: absolute;
//     content: '';
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: url('../images/front-page-sport/background.png') repeat center / auto;
//     z-index: 0;
//   }
    // background: url('../images/front-page-sport/background.jpg') no-repeat center / cover;
    // background-attachment: fixed;
    background: rgba(0,0,0,1);
    // background: url('../images/front-page-sport/background2.png') no-repeat center / cover;
  }

.home-bg2{
    // background: url('../images/front-page-sport/background.jpg') no-repeat center / cover;
    // background: url('../images/front-page-sport/background2.png') no-repeat center / cover;
    // background-attachment: fixed;
    background: rgba(0,0,0,1);
}
// 背景圖
.login-bx{
    position: relative;
    &::before{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: url('../images/front-page-lottery/background.jpg') no-repeat center / cover;
      // background: url('../images/front-page-sport/background2.png') no-repeat center / cover;
      z-index: 0;
      background: #000;
      
     
    }
   
  }
  
.register-bx{
    position: relative;
    &::before{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: url('../images/front-page-lottery/background.jpg') no-repeat center / cover;
      z-index: 0;
      background: #000;
    }
   
}
.fixBx{
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    background: #16161A;
    max-width: 450px;

}

// 遊戲頁 自動手動切換
.changModelBtn{
    img{
        left: auto !important;
    }
    img:last-child{
        display: block !important;
    }
}

.MarqueeBx{
  position: relative;
  padding-left: 30px;
  background: #1E1E21;
  // width: calc(100% - 24px);
  // margin: 0 auto;
  margin-top: 2px;
  img{
    position: absolute;
    top: 50%;
    left: 5px;
    width: 20px;
    transform: translateY(-50%);
    z-index: 2;
  }
}
// 會員資料、金額
.memberWrap {
    height: 40px;
    background-color: white;
    padding: 0 0.5rem;
    font-weight: 700;
    img{
      width: 24px;
      margin-right: 5px;
    }
    .left {
      flex-direction: column;
      justify-content: center;
      font-size: 13px;
      width: 50%;
      border-right: 1px solid #e2efff;
      color: var(--btnblue);
      letter-spacing: 1px;
      >span{
        display: flex;
        align-items: center;
      }
    }
    .right {
      width: 50%;
      align-items: center;
      color: var(--btnblue);
      // justify-content: space-evenly;
      padding-left: 10px;
      letter-spacing: 1px;
      font-size: 12px;
      display: block;
      >span{
        display: flex;
        align-items: center;
      }
      .amount {
        color: #43cea2;
        display: block;
        margin-left: 3px;
      }
      svg {
        font-size: 1rem;
      }
      button {
        padding: 3px 5px;
        background-color: var(--darkblue);
        color: white;
        border-radius: 5px;
        border: none;
        font-size: 12px;
        margin-left: 0.5rem;
      }
    }
}
// 管理中心標題
.center-ti{
    // text-align: center;
    position: relative;
    margin-bottom: 15px;
    span{
        display: inline-block;
        padding-left: 15px;
        // border: 1px solid #fff;
        // border-radius: 10px;
        color: #fff;
        // background: rgba(#fff,.3);
        // box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 15px -2px #FFFFFF;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 2px;
            width: 5px;
            height: 20px;
            background: #F7D597;
            border-radius: 6px;
        }
        // &::after{
        //     position: absolute;
        //     content: '';
        //     top: 50%;
        //     transform: translateY(-50%) rotate(45deg);
        //     right: -40px;
        //     width: 5px;
        //     height: 5px;
        //     background: #fff;
        // }
    }
    // .line-l{
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //     width: calc(50% - 85px);
    //     height: 1px;
    //     background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
       
    // }
    // .line-r{
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //     width: calc(50% - 85px);
    //     height: 1px;
    //     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    // }
}
.center-ti.agent-ti{
  span::before{
    background: #FF8FAD;
  }
}


// page
.MuiPagination-root{
    li>button{
        color: #E2EFFF;
    }
    li>button.Mui-selected{
        background: rgba(64, 50, 148, 0.6);
    }
}
.MuiInputBase-root{
  &::after{
    display: none !important;
  }
}
.css-q0jhri-MuiInputBase-root-MuiInput-root-root-root {
  label{
    line-height: 1.1;
    top: -2px;
  }
}
input , select {
    width: 100% !important;
    height: 40px !important;
    border-radius: 10px;
    padding: 0 !important;
    padding-left: 3rem !important;
    border: 1px solid var(--bordergrey) !important;
    font-size: 16px;
    background: rgba(#fff,.5) !important;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 0;
      color: #3D4152 !important;
      opacity: 1 !important;
    }
}

.com-btn {
    width: 80%;
    height: 40px;
    color: white;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 30px;
    background: none;
    border: 1px solid #34B6FF;
    background: none;
    padding-left: 0;
    background: none;
}
.com-btn2{
  border-radius: 4px;
  background: rgba(64, 50, 148, 0.6);
  color: white;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
  display: block;
  margin: 0 auto;
}
button[disabled]{
  background: rgba(255, 255, 255, 0.5);
}

ul{
  padding-left: 0;
}

.walletPop{
   .mainWallet{
    margin-top: 20px;
    border: 2px solid #F7D597;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
   }
   .botBx{
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 2px solid #F7D597;
    padding-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    >div{
      
      padding: 5px;
      padding-bottom: 10px;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #F7D597;
      &:not(:last-child){
        border-bottom: 2px solid #F7D597;
      }
      >div{
        width: 50%;
      }
      button{
        width: 24%;
        border: 2px solid #F7D597;
        padding: 3px 10px;
        background: none;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
      }
    }
   }
   .ruleDes{
    height: auto;
    padding-bottom: 30px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    background: rgba(0,0,0,.7);
   }
   .buttonBx{
    display: flex;
    justify-content: space-between;
    >div{
      width: 48%;
    }
    button{
      border: 2px solid #F7D597;
      padding: 3px 10px;
      background: none;
      display: block;
      margin: 0 auto;
      border-radius: 10px;
    }
   }

}

.walletEditPop{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  height: 0;
  z-index: 101;
  width: 100vw;
  max-width: 450px;
  height: 100vh;
  overflow: auto;
  padding: 0 10px;
 
  .editBx{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 70%;
    height: auto;
    padding: 10px;
    padding-top: 20px;
  }
  .ruleClose{
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background: #4E90CC;
    border-radius: 50%;
    color: #fff;
    svg{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .inputWrap{
    display: flex;
    align-items: center;
  }
  .radionBtn{
    display: flex;
    align-items: center;
    margin-left: 10px;
    input{
      width: 30px !important;
      height: 30px !important;
      margin-right: 5px;
     
    }
  }
  .loginInput{
    input{
      padding-left: 1rem !important;
    }
  }

  button{
    border: 2px solid #4E90CC;
    padding: 3px 10px;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    background: none;
  }
}

.ruleWrap.creditWrap{
  .title{
    color: red;
    font-size: 20px;
    margin-bottom: 10px;
  }
  input{
    padding-left: 0px !important;
    border: none !important;
    border-radius: 0 !important;
  }
  button{
    border: 2px solid #4E90CC;
    padding: 3px 10px;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    background: none;
  }
  .imgBx{
    >div{
      margin-bottom: 20px;
    }
    img{
      width: 100% !important;
    }
  }
}
