.ActivityPage{
    padding-top: 15px;
    padding-bottom: 120px;
    min-height: 70vh;
    .activityList{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        li{
            width: 47%;
            margin-bottom: 35px;
            .date{
                font-size: 12px;
                text-align: right;
                color: #fff;
            }
            .borderBx{
                border-radius: 10px;
                border: 1px solid #B4DBFF;
                background: rgba(119, 157, 255, 0.22);
                padding: 10px 15px 20px 15px;
                text-align: center;
                img{
                    width: 100%;
                }
            }
            .title{
                font-size: 24px;
                color: #fff;
            }
            .des{
                font-size: 16px;
                color: #fff;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.50) 47.92%, rgba(255, 255, 255, 0.00) 100%);
            }
        }
    }
    .MuiPagination-root{
        width: 100vw;
        position: relative;
        left: -12px;
    }
    .MuiPagination-root ul{
        width: 100%;
        flex-wrap: nowrap;
        li{
            width: calc(100% / 9);
            height: 49px;
            border: 1px solid #fff;
            position: relative;
            &:not(:last-child){
                border-right: none;
            }
            button{
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                border-radius: 0;
            }
            &:first-child,&:last-child{
                svg{
                    display: none;
                }
            }
            &:first-child{
                width: 15%;
                border-left: none;
                button::before{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 45%;
                    transform: translate(-50%,-50%);
                    width: 19px;
                    height: 28px;
                    background: rgba(119, 157, 255, 0.5);
                    clip-path: polygon(100% 0, 0 50%, 100% 100%);
                }
            }  
            &:last-child{
                width: 15%;
                border-right: none;
                button::before{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 55%;
                    transform: translate(-50%,-50%);
                    width: 19px;
                    height: 28px;
                    background: rgba(119, 157, 255, 0.5);
                    clip-path: polygon(0 0, 100% 50%, 0 100%);
                }
            }
        }
    }
    .MuiPaginationItem-root {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 0%;
        width: 100%;
        margin: 0;
        padding: 0;
        transform: translateY(-50%);
    }
    .css-1v2lvtn-MuiPaginationItem-root{
        top: 60%;
    }
    .MuiPagination-root li > button.Mui-selected{
        // background: none;
        background: rgba(119, 157, 255, 0.22);
        border-radius: 0;
    }
    .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled{
        opacity: 1;
        background: none;
        pointer-events: none;
        &::before{
            background: rgba(255, 255, 255, 0.5) !important;
        }
    }
    
}

.ActivityDetailPage{
    padding-bottom: 100px;
    .date{
        text-align: center;
        position: relative;
        margin-bottom: 0px;
        span{
            background-image: linear-gradient(to right, rgba(193, 240, 255, 1) 20%, rgba(255, 251, 211, 1) 40%, rgba(255, 199, 226, 1) 70%, rgba(255, 199, 249, 1) 100%);                
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent; 
            border: none;
            box-shadow: none;
            &::before{
                left: 0px;
            }
            &::after{
                right: 0;
            }
        }
        .line-l,.line-r{
            width: calc(50% - 100px);
        }
    }
    .title{
        color: #FFFCE3;
        font-size: 24px;
        letter-spacing: 1.92px;
        background: linear-gradient(270deg, rgba(182, 202, 255, 0.00) 0%, rgba(85, 133, 255, 0.27) 47.92%, rgba(150, 179, 255, 0.00) 100%);
        border-top: 1px solid #B4DBFF;
        border-bottom: 1px solid #B4DBFF;
        text-align: center;
        padding: 5px 20px;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url('../../images/activity/star2.png') no-repeat center / contain;
            width: 45px;
            height: 50px;
        }
        &::after{
            position: absolute;
            content: '';
            right: 0;
            top: -15px;
            background: url('../../images/activity/star1.png') no-repeat center / contain;
            width: 45px;
            height: 37px;
        }
    }
    .img-bx{
        margin-top: 45px;
        min-height: 255px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border: 2px dashed rgba(111, 220, 255, 0.50);
            border-radius: 50%;
            width: 282px;
            height: 282px;
            pointer-events: none;
        }
        img{
            width: 85%;
            display: block;
            margin: 0 auto;
            position: relative;
            z-index: 2;
        }
    }
    .des-bx{

    }
    .desBx{
        width: 86%;
        color: #fff;
        font-size: 20px;
        margin: 0 auto;
        margin-top: 45px;
        border: 1px solid rgba(195, 222, 255, 0.62);
        padding: 10px 20px;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: calc(100% + 26px);
            height: calc(100% + 24px);
            border: 1px solid rgba(195, 222, 255, 0.62);
            border-radius: 22px;
            pointer-events: none;

        }
        .SignUp{
            width: 50%;
            background: none;
            border: none;
            border: 1px solid #34B6FF;
            border-radius: 5px;
            margin: 10px auto;
            display: block;
        }
    }
    .backBtn{
        background: none;
        border: none;
        width: 56px;
        height: 54px;
        margin: 0 auto;
        margin-top: 55px;
    }
}