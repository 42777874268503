.homePage {
  min-height: auto;
  padding-bottom: 100px;
  padding-top: 20px;
  min-height: calc(100vh - 278px);
  .MuiPagination-root{
    width: 100%;
    margin-top: 10px;
  }
  // 選單icon
  .menuWrap {
    // margin-top: 3rem;
    display: none;
    .items {
      justify-content: center;

      a {
        cursor: pointer;
        width: 180px;
        height: 75px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-direction: column;
        background-color: white;
        margin: 0 1.5rem;

        .icon.active path {
          fill: var(--midblue);
        }
        span {
          font-size: 1.5rem;
          color: var(--darkblue);
        }
      }
    }
  }

  // 遊戲卡
  .gamesWrap {
    margin-top: 4rem;
    margin-left: auto;
    // margin-right: auto;
    display: block;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 76%;
    // min-height: 650px;
    padding-bottom: 40px;
    position: relative;
    z-index: 5;
    .MuiPagination-root{
      position: absolute;
      bottom: -10px;
      width: 100%;
      left: 0;
    }
    .gameCard {
      width: auto;
      position: relative;
      cursor: pointer;
      transition: 0.3s;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      img{
        width: 100%;
      }

      .icon svg {
        font-size: 4.5rem;
        margin-top: 0.5rem;
      }
      .countDown{
        position: absolute;
        top: 0px;
        right: 10px;
        color: #fff;
        span {
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          color: #e9ebf1;
          letter-spacing: 2px;
        }
      }
      .hot {
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: #de3333;
        top: -10px;
        left: -15px;
        text-align: center;
        position: absolute;
        font-size: 1.5rem;
        border-radius: 3px;
      }

      .tag {
        width: 46px;
        height: 46px;
        font-size: 2rem;
        text-align: center;
        line-height: 40px;
        border-radius: 100px;
        border: 2px solid white;
      }
      span {
        height: 46px;
        line-height: 46px;
        font-size: 20px;
        color: #e9ebf1;
        letter-spacing: 5px;
      }
      h5 {
        font-size: 2rem;
        font-weight: 400;
        color: #e9ebf1;
        letter-spacing: 5px;
      }
      .mobileCardWord {
        display: none;
        position: absolute;
        bottom: -40px;
        color: var(--darkblue);
      }
      .des{
        color: #fff;
        font-size: 12px;
        margin-top: 2px;
        padding: 0 3px;
      }
    }
    .listBx{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
     
    }
    &.fourList{
      display: flex;
      .gameCard{
        width: calc((100% - 15px) / 4);
        margin-bottom: 5px !important;
        &:not(:nth-child(4n)){
          margin-right: 5px;
        }
      }
    }

    .articleBx{
      .imgBx img{
        width: 100%;
      }
      .content{
        font-size: 20px;
        margin: 15px 0;
        color: #fff;
        p{
          margin-bottom: 0;
          line-height: normal;
        }
      }
      .backBtn{
        display: block;
        margin: 0 auto;
        width: 56px;
        height: 54px;
      }
    }
  }

  .buttons {
    // display: none;
  }
}
.flex{
  display: flex;
}
// 手機版subNav
.mobileSubNav {
  display: none;
  align-items: center;
  height: 40px;
  background-color: white;
  padding: 0 1rem;

  .loginBtn {
    align-items: center;
    font-size: 1rem;
    color: var(--darkblue);
    svg {
      font-size: 1.2rem;
    }
  }
}

 // 左側按鈕
 .buttons {
  display: block;
  margin-top: 0.8rem;
  z-index: 2;
  position: relative;
  padding:0 5px;
  padding-right: 8px;
  width: 20%;
  position: fixed;
  left: 10px;
  // width: calc(100% - 360px);
  height: calc(100% - (59vw + 132px));
  // padding-bottom: 80px;

  overflow-y: auto;
  width: 100%;
  max-width: 450px;
  // top: calc(54vw + 45px);
  left: 50%;
  transform: translateX(-49%);
  z-index: 2;
  // pointer-events: none;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
  @media (min-width:550px) {
    top: calc(54vw + 13px);
  }
  @media (min-width:768px) {
    top: 350px;
    height: calc(100vh - 440px);
    
  }
  &::-webkit-scrollbar{
    display: none;
  }
  button {
    width: 20%;
    // width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 6px;
    // box-shadow: 0px 4px 4px 0px #0000001a;
    display: block;
    border: 1px solid rgba(#FFFFFF , .2);
    padding: 7px;
    // background-color: white;
    color: var(--darkblue);
    background: #1E1E24;
    pointer-events: auto;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    &:nth-child(1){
      img{
        position: relative;
        // left: 2px;
        // top: 8px;
        pointer-events: none;
      }
    }
  }
  .imgBx{
    // width: 100%;
    width: 30px;
    margin: 0 auto;
  }
  img{
    width: 100%;
  }
  img:last-child{
    display: none;
  }
  button[data-active=true]  {
    border-color: #F7D597;
    img:first-child{
      display: none;
    }
    img:last-child{
      display: block;
    }
    .title{
      color: #F7D597;
    }
   
  }
  .title{
    margin-top: 7px;
    color: #fff;
    font-size: 12px;
  }
}

// 手機版
@media (max-width: 768px) {
  .menuWrap {
    display: none;
  }

  .profilePage.homePage{
    padding-top: 10px;
  }
  .homePage {
    // display: flex;
    padding-bottom: 100px;
    padding-top: 0px;
    min-height: calc(100vh - 278px);
    .homeBx{
      justify-content: space-evenly;
     
   
    }
    .gameSerachInput{
      margin-left: auto;
      width: 100% !important;
      display: block;
      margin-top: 10px;
      position: relative;
      padding-left: 20px !important;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .gamesWrap {
      justify-content: start;
      align-content: flex-start;
      margin-top: 0.8rem;
      margin-left: auto;
      margin-right: 0;
      // width: 346px;
      // width: 100%;
      // width: 96%;
      width: 76%;
      // height: 45vh;
      // overflow-y: auto;
      // height: calc(100% - (54vw + 132px));
      .gameCard {
        text-align: center;
        width: 100%;
        &:not(:last-child){
          margin-bottom: 20px;
        }
        // width: 95px;
        // height: 126px;
        // margin: 0rem;
        // margin-bottom: 2rem;
        // margin-right: 12px;
        // padding: 1rem 0.5rem;

        // 熱門標示
        // .hot {
        //   width: 27px;
        //   height: 27px;
        //   font-size: 14px;
        //   line-height: 27px;
        //   top: -10px;
        //   left: -10px;
        // }
        // .titleWrap {
        //   flex-direction: column;
        //   align-items: center;
        //   .tag {
        //     width: 30px;
        //     height: 30px;
        //     font-size: 1.2rem;
        //     line-height: 28px;
        //     border: 1.5px solid white;
        //     margin-bottom: 0.5rem;
        //   }
        //   span {
        //     height: 30px;
        //     line-height: 30px;
        //     font-size: 1rem;
        //     letter-spacing: 3px;
        //   }
        //   h5 {
        //     font-size: 18px;
        //     letter-spacing: 3px;
        //   }
        // }
        // .icon {
        //   svg {
        //     width: 45px;
        //     height: 45px;
        //     margin-top: 0rem;
        //   }
        // }
        // // 小字
        // .mobileCardWord {
        //   display: block;
        // }
      }
    }
   
  }

  // 手機版subNav(banner 下方)
  .mobileSubNav {
    display: flex;
  }
}



// 跑馬燈
.marquee-container{
  background: #332B3A ;
  color: #fff;
  padding: 3px 0;
  height: 28px;
  // .marquee >div{
  //   width: 100vw;
  // }
}


.mySwiper{
  display: block;
  transform: rotate(90deg);
  /* width: 20%; */
  height: 70px;
  // position: fixed;
  // left: 10px;
}

.mySwiper2{
  // width: calc(100% - 70px);
  width: 76%;
  margin-right: 0;
  .swiper-wrapper{
   
    // height: 100px;
  }
  .swiper-slide{
    background: #fff;
  }
}