.BannerWrap {
  height: 350px;
  padding: 0 12px;
  position: relative;
  border-radius: 15px;
  // &::before,&::after{
  //   position: absolute;
  //   content: '';
  //   left: 0;
  //   width: 100%;
  //   height: 30%;
  //   z-index: 3;
   
  // }
  // &::before{
  //   top: -1px;
  //   background: linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(255,255,255,0) 50%);
  // }
  // &::after{
  //   bottom: -1px;
  //   background: linear-gradient(to top,rgba(0,0,0,1) 0%,rgba(255,255,255,0) 50%);
  // }
  p {
    background-color: #332b3a;
    color: white;
    padding: 0.5rem 0.8rem;
    // position: absolute;
    // bottom: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .ant-carousel .slick-dots {
    justify-content: center;
    bottom: 50px;
    margin-right: 5%;
  }

  // 輪播牆
  .ant-carousel .slick-dots li {
    height: 8px;
    margin: 0 5px;

    button {
      height: 100%;
      border-radius: 5px;
    }
  }
}

// 手機版
// @media (max-width: 768px) {
  .BannerWrap {
    height: auto;
    border-radius: 15px;
    margin-top: 3px;
    p {
      top: 0;
      bottom: auto;
    }
    .ant-carousel{
      height: 37vw;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 0.1rem 0.14rem 0.04rem rgb(67 67 67 / 50%), inset 0 -0.02rem 0 0 hsla(0deg, 0%, 100%, 0.8);
      @media (min-width:450px) {
        height: 37vw;
        max-height: 243px;
      }
      .bannerImg{
        width:100% ;
        height: 38.5vw;
        object-fit: cover;
        border-radius: 15px;
        @media (min-width:450px) {
          height: 38.5vw !important;
          max-height: 243px;
        }
      }
    }
    .ant-carousel .slick-dots {
      bottom: 0.5rem;
      margin-right: 2%;
    }
    .ant-carousel .slick-dots li {
      height: 10px;
      width: 10px;
      margin: 0 4px;
      opacity: 1;
      &.slick-active{
        width: 36px;
        button{
          background: #C18D4A;
        }
       
     
      }
      button{
        opacity: 1;
      }
    }
  }
// }
