.gamePage .gameWrap.fourBallWrap .itemWrap .circleWrap .name{
    width: 31% !important;
}
.gamePage{
    .gameBotBx{
        padding-bottom: 70px;
    }
}
.gamePage .gameWrap.fourBallWrap {
   
    .selNum{
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        height: 38px;
        span{
            font-weight: 700;
            font-size: 24px;
            margin-left: 10px;
        }
    }
}