// 交易員共用
.tradersList{
    padding-left: 0;
    li{
        border: 1px solid #B4DBFF;
        border-radius: 5px;
        background: rgba(#CDD6EB,.25);
        display: flex;
        align-items: center;
        padding: 5px 10px;
        &:not(:last-child){
            margin-bottom: 15px;
        }
        .avatar{
            width: 15%;
            padding-right: 3%;
            min-width: 42px;
            img{
                width: 100%;
            }
        }
        .d2{
            width: 30%;
            .tName{
                font-size: 16px;
                color: #fff;
            }
            .number{
                display: flex;
                align-items: center;
                img{
                    width: 15px;
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }
                span{
                    font-size: 12px;
                    color: #8CD4FF;
                }
            }
        }
        .d3{
            width: 35%;
            .incomeTi{
                font-size: 12px;
                color: #fff;
            }
            .incomeDes{
                color: #8CD4FF;
                font-size: 16px;
                line-height: 1.1;
            }
        }
        button{
            width: 20%;
            min-width: 65px;
            color: #fff;
            background: #05164B;
            border-radius: 5px;
            border: none;
            padding: 4px;
            &.full{
                background: #AC1F00;
            }
        }
    }
}
.traderHeader{
    background: #fff;
    width: 100%;
    height: 40px;
    position: relative;
    .backBtn{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .title{
        text-align: center;
        font-size: 16px;
        line-height: 2.7;
    }
    svg{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 26px;
        height: 26px;
        color: #737787;
    }
}
// 頭像區
.avatarStatusBx{
    position: relative;
}
.avatarStatus{
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 10px;
    padding-bottom: 20px;
    position: relative;
    >img{
        min-width: 70px;
        width: 19%;
        margin-right: 5%;
    }
    .desBx{
        width: 76%;
        color: #fff;
        position: relative;
        .name{
            font-size: 20px;
        }
        .age{
            font-size: 12px;
            color: rgba(#FFFFFF,.6);
        }
        .numBx{
            display: flex;
            align-items: center;
            span{
                position: relative;
                top: 2px;
                margin-left: 5px;
            }
            .number{
                margin-right: 20px;
                color: #8CD4FF;
            }
            .follow{
                color: #FF98DC;
            }
        }
        .online{
            position: absolute;
            top: 0;
            right: 0;
            color: #43CEA2;
            padding-left: 18px;
            &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background: #43CEA2;
            }
            &.red{
                color: #FF7354;
                &::before{
                    background: #FF7354;
                }
            }
        }
    }
   
}
.botLine{
    position: absolute;
    bottom: 0;
    left: 10px;
    background: rgba(#8ABEFF,.5);
    width: calc(100% - 20px);
    height: 1px;
    &::before,&::after{
        position: absolute;
        content: '';
        left: -1px;
        top: -2px;
        // transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: rgba(#8ABEFF,.5);
    }
    &::after{
        left: auto;
        right: -1px;
    }
}
.btnFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    button{
        width: 48%;
        padding: 6px 0;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        border: none;
        margin-top: 15px;
        &.full{
            background: rgba(255, 115, 84, 0.63) !important;
        }
        &.Cancel{
            border: 1px solid #FF96D5 !important;
            background: none !important;
        }
        &.mirBtn{
            background: rgba(#64A8FF,.7);
        }
        &.subBtn{
            background: rgba(#FF59D1,.7);
        }
    }
}
.tagBtnFlex{
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    >div{
        padding: 2px 10px;
        color: #fff;
        border-radius: 10px;
        background: #185A9D;
        &:not(:last-child){
            margin-right: 10px;
        }
    }
}
.incomeRate{
    margin-top: 30px;
    padding: 0 10px;
    .title{
        font-size: 20px;
        color: #fff;
    }
    span{
        font-size: 24px;
        color: #7DCFFF;
        display: block;
        font-weight: 700;
    }
    .testBorder{
        margin-top: 15px;
        border: 1px solid #fff;
        height: 100px;
    }
    &.flex{
        padding-bottom: 20px;
        align-items: flex-end;
        .title{
            width: 50%;
            color: rgba(255, 255, 255, 0.60);
            font-size: 16px;
        }
        span{
            font-size: 20px;
        }
        .testBorder{
            width: 50%;
            height: 60px;
        }
    }
}
// 交易員共用數據列表
.traderDataList{
    padding: 0 10px;
    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        border-bottom: 1px solid #fff;
        padding-bottom: 5px;
        font-size: 16px;
        &:not(:last-child){
            margin-bottom: 15px;
        }
    }
}
// 交易員各頁
.tradersPage{
    padding-top: 0;
    .container{
        min-height: 60vh;
    }
    .banner{
        position: relative;
        img{
            width: 100%;
        }
        button{
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%,-50%);
            border: none;
        }
    }
    .serach-bx{
       
        ul{
            display: flex;
            justify-content: space-between;
            color: #fff;
            margin-top: 15px;
            font-size: 16px;
            line-height: 20px;
            li[data-active=true]{
                // background-image: linear-gradient(180deg, #B4DBFF, #fff, #B4DBFF 100%);                -webkit-background-clip: text;
                // background-clip: text;
                // color: transparent; 
                color: #85DAFF;
                // color: #B4DBFF;
                
            }
            li{
                width: 21%;
                &:last-child{
                    width: 35%;
                }
            }
        }
        .timeBx{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 20px;
            >div{
                width: 23%;
                height: 34px;
                border-radius: 5px;
                border: 1px solid #FFF;
                background: rgba(211, 214, 226, 0.25);
                color: #FFF;
                font-size: 16px;
                text-align: center;
                line-height: 2;
            }
            >div[data-active=true]{
                border: 1px solid #48B3F2;
                background: rgba(151, 172, 255, 0.25);

            }
        }
    }
}
.joinTradersPage{
    padding-top: 0;
    .container{
        min-height: auto;
    }
    .banner{
        position: relative;
        img{
            width: 100%;
        }
        button{
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%,-50%);
            border: none;
            min-width: 181px;
            height: 40px;
            border-radius: 5px;
            color: #fff;
            border: none;
            background: #05164B;
        }
    }
    .iconList{
        width: 82%;
        margin: 0 auto;
        margin-top: 50px;
        li{
            border: 1px solid #A1CAFE;
            border-radius: 5px;
            background: rgba(#185A9D,.15);
            color: #fff;
            padding: 5px 0 5px 26%;
            &:not(:last-child){
                margin-bottom: 15px;
            }
            span{
                margin-left: 15px;
            }
        }
    }
    .conditionBx{
        width: 82%;
        margin: 0 auto;
        margin-top: 60px;
        .title{
            font-size: 20px;
            color: #fff;
            font-weight: 700;
            margin-bottom: 15px;
        }
        ul{
            li{
                padding-bottom: 10px;
                padding-left: 10px;
                border-bottom: 1px solid #fff;
                color: #fff;
                font-size: 16px;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                svg{
                    color: #A3CBFF;
                    width: 17px;
                }
                span{
                    margin-left: 10px;
                }
            }
        }
        button{
            color: #fff;
            background: rgba(#5C9FFF,.6);
            border-radius: 5px;
            width: 80%;
            margin:  0 auto;
            margin-top: 20px;
            border: none;
            padding: 5px;
            display: block;
            font-size: 16px;
        }
    }
}
.traderHomePage{
    padding-top: 0;
    min-height: 100vh;
}
.traderMyHomePage{
    padding-top: 0;
    min-height: auto;
    .myList,.myFollow{
        padding: 0 10px;
        .moAttention{
            color: rgba(#fff,.7);
            font-size: 14px;
        }
        .title{
            font-size: 20px;
            font-weight: 500;
            color: #fff;
            margin-bottom: 10px;
        }
    }
    .myFollow{
        .tradersList{
            .d2{
                .sTitle{
                    color: #B0D3FF;
                    font-size: 14px;
                }
                .tName{
                    color: #FFF;
                    font-size: 16px;
                }
            }
            .d3{
                .online{
                    position: relative;
                    color: #43CEA2;
                    padding-left: 18px;
                    &::before{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        background: #43CEA2;
                    }
                    &.red{
                        color: #FF7354;
                        &::before{
                            background: #FF7354;
                        }
                    }
                }
            }
            button{
                background: #3394FF;
            }
        }
    }
}
.setPop{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 30;
    width: 90%;
    // height: 300px;
    border-radius: 10px;
    border: 1px solid #A1CAFE;
    background: #EFF7FF;
    text-align: center;
    padding: 35px 20px 25px 20px;

    >svg{
        width: 66px;
        height: 66px;
        color: #185A9D;
    }
    .des{
        color: #737787;
        font-size: 20px;
        font-weight: 600;
    }
    .MuiFormGroup-root{
        .MuiFormControlLabel-root{
            justify-content: center;
        }
        .MuiTypography-root{
            color: #737787;
        }
       

    }
    .btnFlex{
        display: flex;
        button{
            width: 45%;
            height: 33px;
            line-height: 1;
        }
        justify-content: space-between;
        button:first-child{
            border-radius: 5px;
            border: 1px solid #6A95CC;
            background: #FFF;
            color: #737787;
            font-size: 16px;    
        }
        button:nth-child(2){
            font-size: 16px;    
            border-radius: 5px;
            background: #6A95CC;
            color: #fff;
        }
    }
}
.traderSetPage{
    padding-top: 0;
  
    .proportionBx{
        margin-top: 15px;
        .f16{
            padding: 0 10px;
        }
        .flex{
            display: flex;
            justify-content: space-between;
        }
        .btn{
            border-radius: 5px;
            border: 1px solid #FFF;
            background: rgba(255, 255, 255, 0.36);
            width: 18%;
            color: #fff;
        }
        .btn[data-active=true]{
            background: rgba(70, 186, 255, 0.69);
        }
    }
    form{
        padding: 0 10px;
        label{
            color: #FFF;
            font-size: 16px;
        }
        input{
            padding-left: 10px;
            color: #fff;
            font-size: 16px;
        }
        span{
            display: block;
            color: rgba(255, 255, 255, 0.70);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.8px;
        }
        button{
            border-radius: 5px;
            background: rgba(92, 159, 255, 0.62);
            border: none;
            width: 100%;
            margin-top: 10px;
            font-size: 16px;

        }
    }
    input{
        border-radius: 5px;
        border: 1px solid #7ABAFF;
        background: rgba(150, 202, 255, 0.20);
        padding-left: 10px !important;
    }
}

.traderRecordPage{
    padding-top: 0;
    .refreshBtn{
        border-radius: 5px;
        background: rgba(24, 90, 157, 0.76);
        min-width: 100px;
        height: 27px;
        text-align: center;
        border: none;
        margin-left: auto;
        color: #fff;
        margin-top: 15px;
        margin-left: 7px;
    }
    .dataFlex{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        input{
            padding-left: 0px;
            border-radius: 5px;
            height: 31px;
            text-align: center;
            color: #132766;
        }
        span{
            color: #fff;
            font-size: 16px;
            min-width: 20px;
            margin: 0 5px;
            text-align: center;
        }
        button{
            border-radius: 5px;
            background: rgba(24, 90, 157, 0.76);
            min-width: 65px;
            height: 27px;
            text-align: center;
            border: none;
            margin-left: auto;
            color: #fff;
            margin-top: -10px;
        }
        
        .react-datepicker-wrapper{
            width: calc((100% - 20px) / 2);
      
            input{
              width: 100%;
              box-shadow: none;
              border: 1px solid #E2EFFF;
              color: #E2EFFF;
              background: none;
              text-align: center;
              height: 30px;
              font-size: 14px;
              padding-left: 0px !important;
            }
          }
    }
    .recordBx{
        padding: 0 25px;
        color: #fff;
        .recordItem{
            &:not(:last-child){
                margin-bottom: 40px;
            }
            &.red{
                .title,.desTitle{
                    background: rgb(red, 0.36);
                }
            }
        }
        .title{
            display: flex;
            justify-content: space-between;
            padding: 0 20%;
            background: rgba(125, 207, 255, 0.36);
            color: #fff;
            font-size: 16px;

        }
        .content{
            text-align: center;
        }
        .desTitle{
            display: flex;
            justify-content: space-between;
            padding: 0 7%;
            background: rgba(125, 207, 255, 0.36);
            color: #fff;
            font-size: 16px;
            div:nth-child(1){
                width: 50%;
            }
            div:nth-child(2){
                width: 25%;
                text-align: center;
            }
            div:nth-child(3){
                width: 25%;
                text-align: center;
            }
        }
        .betC{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1px 7%;
            border-bottom: 1px solid #fff;
            .green{
                color: green;
                font-weight: 700;
                font-size: 18px;
            }
            .red{
                color: red;
                font-weight: 700;
                font-size: 18px;
            }
            div:nth-child(1){
                width: 50%;
            }
            div:nth-child(2){
                width: 25%;
                text-align: center;
            }
            div:nth-child(3){
                width: 25%;
                text-align: center;
            }
        }
    }
    .MuiPagination-root{
        margin-top: 40px;
    }
    .MuiPagination-root li > button.Mui-selected{
        background: rgba(125, 207, 255, 0.36);
    }
    input{
        padding-left: 10px !important;
    }

    .btnflex{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 10px;
        button{
            margin-right: 15px;
        }
        span{
            color: #fff;
        }
        >div{
            width: 40%;
        }
        select{
            padding-left: 0 !important;
            text-align: center;
        }
    }
}


.f16{
    font-size: 16px;
    color: #FFF;
}