.logo{
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  width: 200px;
  height: 40px;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
  background: url('../../images/front-page-sport/logo.png') no-repeat center / cover;
  background: url('../../images/front-page-lottery/b18logo.png') no-repeat center / cover;
  width: 150px;
  height: 150px;
  top: -165px;

}
.registerWrap .logo{
  width: 70px;
  height: 70px;
  top: -80px;
}
// 登入表單
.loginWrap , .registerWrap {
  width: 74%;
  max-width: 750px;
  // min-width: 330px;
  height: 500px;
  // display: flex;
  border-radius: 10px;
  // overflow: hidden;
  border: 1px solid #fff;
  box-shadow: inset 0px 0px 0px 0px #ABABAB,0px 0px 15px -2px #FFFFFF;
  height: 660px;
  position: relative;
  z-index: 2;
  text-align: right;
  @media (min-width:600px) {
    // height: 400px;
    transform: scale(.9);
    height: 400px;
  }
  form {
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // background-color: white;
   

    h3 {
      text-align: center;
      padding-bottom: 10px;
      width: 100px;
      // border-bottom: 2px solid var(--midblue);
      margin-bottom: 25px;
      color: #fff;
      font-weight: 400;
      font-size: 18px;
      position: relative;
      &::before{
        position: absolute;
        content: '';
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 2px;
        background: var(--midblue);
        border-radius:99px;
      }
    }

   
    .phoneInput{
      display: flex;
      justify-content: space-between;
      select{
        padding-left: 10px;
        width: 40%;
      }
      input{
        width: 55%;
        padding-left: 15px;
      }
    }
    // 登入btn
    .loginBtn {
      width: 80%;
      height: 40px;
      color: white;
      font-size: 18px;
      font-weight: 400;
      border-radius: 10px;
      margin-top: 30px;
      background: none;
      border: 1px solid #34B6FF;
    }
    .links {
      width: 300px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      // margin-top: 1rem;
      position: absolute;
      bottom: 9px;
      padding: 0 1rem;
      a{
        color: #A0CFFF;
        border-bottom: 1px solid #A0CFFF;
        line-height: 1.1;
        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }
  }

  // 註冊btn
  .registerBtn {
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 3px;
    color: #fff;
    margin-top: 20px;
    display: inline-block;
    width: auto;
    height: 27px;
    h3 {
      color: white;
      font-size: 16px;
    }
  }
}
.registerWrap{
  @media (min-width:600px) {
    // height: 400px;
    transform: scale(.8);
    height: 690px;
  }
}

@media (max-width: 768px) {
  .loginWrap {
    // width: 95%;
    height: 375px;

    form {
      padding: 1rem;

      h3 {
        width: auto;
      }
      .loginInput {
        input {
          height: 40px;
        }
      }

      input[type="submit"] {
        width: 190px;
        height: 40px;
        margin-top: 1rem;
        font-size: 1.1rem;
      }
      .links {
        width: 100%;
      }
    }
    .registerBtn {
      // width: 56px;
      
      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .captchaBx{
      >div{
        display: flex;
        align-items: center;
        canvas{
          margin-right: 10px;
        }
      }
    }
  }

  .registerWrap{
    height: 490px;
    form .links {
      width: 100%;
    }
    form input::placeholder{
      font-size: 12px;
    }
    .captchaBx{
      >div{
        display: flex;
        align-items: center;
        canvas{
          margin-right: 10px;
        }
      }
    }
  }
}

.loginInput {
  width: 100%;
  position: relative;
  // input {
  //   width: 100%;
  //   height: 40px;
  //   border-radius: 10px;
  //   padding-left: 3rem;
  //   border: 1px solid var(--bordergrey);
  //   font-size: 16px;
  //   background: rgba(#fff,.5);
  //   &:focus {
  //     outline: none;
  //   }
  //   &::placeholder {
  //     font-size: 16px;
  //     line-height: 0;
  //     color: #3D4152;
  //     // opacity: 0.5;
  //   }
  // }
  svg {
    position: absolute;
    font-size: 1.3rem;
    color: #392B46;
    left:1rem;
    top: 50%;
    transform: translateY(-48%);
  }
  .icon{
    position: absolute;
    font-size: 1.3rem;
    color: #392B46;
    top: 50%;
    left: 12px;
    transform: translateY(-48%);
  }
}

.loginWrap .flexBx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginWrap .changLanSel{
  margin-top: 20px;
  border-radius: 5px;
  height: 27px !important;
  text-align: center;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: auto !important;
  background: none !important;
  color: #fff;
  appearance:none;
}