// 導覽列
.firstNav {
  background-color: var(--darkblue);
  height: 4rem;
  display: none;
  .clock {
    color: white;
    font-size: 1.2rem;
  }
  .linkItems a {
    font-size: 20px;
    color: white;
    text-decoration: none;
    margin-left: 2rem;
  }
}
// 第二nav
.subNav {
  position: fixed;
  top: 64px;
  height: 4.5rem;
  width: 100%;
  background-color: var(--lightblue);
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;
  z-index: 1029;
  .mobileTopNav {
    display: none;
  }
  .navbar-collapse {
    flex-grow: 0;
    .ruleBtn {
      cursor: pointer;
    }
    .nav-link {
      color: var(--darkblue);
      font-size: 1rem;
    }
    .nav-item {
      margin: 0 0.5rem;

      svg {
        font-size: 1.1rem;
      }
    }
  }
}
// 彈窗
.rulePopWrap {
  .gameItems {
    
  }
  .content {
    width: 500px;
  }
}
// 手機版nav(底部)
.mobileBtmNav {
  // display: none;
  // padding: 0.5rem 1rem;
  display: block;
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 450px;
  height: 80px;
  background-color: #1E2027;
  box-shadow: 0px 4px 20px 0px rgba(#000, .25);
  .navItems {
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .item {
    width: 20%;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    
    .icon svg {
      font-size: 1.8rem;
      color: #c4c4c4;
      margin-bottom: 3px;
    }
    .icon.active svg {
      color: var(--darkblue);
    }
    p {
      color: var(--darkblue);
    }

    img:last-child{
      display: none;
    }
    a.active{
      .imgBx img:first-child{
        display: none;
      }
      .imgBx img:last-child{
        display: block;
      }
    }
  }
}

// 手機版
@media (max-width: 768px) {
  .firstNav {
    display: none;
  }
  .subNav {
    top: 0;
    height: 36px;
    padding: 0 1rem;
    .webNav {
      display: none;
    }
    // 手機版TOP Nav
    .mobileTopNav {
      height: 100%;
      align-items: center;
      display: flex;
      .icon svg {
        font-size: 1.2rem;
        margin-left: 0.5rem;
        color: var(--darkblue);
      }
    }
  }

  // 手機版底不固定Nav
  .mobileBtmNav {
    // display: block;
    // position: fixed;
    // z-index: 20;
    // bottom: 0;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 100%;
    // max-width: 450px;
    // height: 80px;
    // background-color: white;
    // box-shadow: 0px 4px 20px 0px #d3d6e2;
    // border-radius: 12px 12px 0px 0px;
  }
}




.mobileBtmNav{
  .navItems .item{
    &:nth-child(2){
      .imgBx img{
        padding: 0;
      }
    }
    .imgBx{
      width: 32px;
      height: 30px;
      position: relative;
      margin: 0 auto;
      img{
        padding: 3px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .text{
      text-align: center;
      color: #737787;
      font-size: 12px;
      line-height: 1.2;
      margin-top: 5px;
    }
  }
}


