.maq {
    background-color: #332b3a;
    color: white;
    padding: 0.5rem 0.8rem;
    // position: absolute;
    // bottom: 0;
    margin-bottom: 0;
    width: 100%;
  }
input::placeholder {
    overflow: visible;
 }

 .topHeaderBx{
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  background: #16161A;
  padding: 4px 12px;
  height: 40px;
  position: relative;
  z-index: 6;
  .topLogo{
    width: 80px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
  }
  .rURL{
    font-size: 12px;
    color: rgba(176, 176, 179, 0.8);
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    a{
      color: rgba(176, 176, 179, 0.8);
    }
  }
 }