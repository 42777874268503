:root {
  --background: #f3f4fb;
  --darkblue: #132766;
  --midblue: #6a95cc;
  --btnblue: #a1cafe;
  --lightblue: #e2efff;
  --bordergrey: #e1e1e1;
  --linear: linear-gradient(180deg, #185a9d 0%, #6a95cc 100%);
  --orange : #E18820;
  --btnblue:#185A9D;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* border: 1px solid lightblue; */
}
#root{
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  overflow: auto;
  height: 100%;
  /* min-height: 101vh; */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}
#root::-webkit-scrollbar{
  display: none;
}

.MuiPagination-root ul{
  justify-content: center;
}

html, body {
  overscroll-behavior: none;
}