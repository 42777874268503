$header_background_color: #333;
$header_text_color: #FDFDFD;
$alternate_row_background_color: #fff;

$table_width: 750px;
$table_body_height: 300px;
$column_one_width: 200px;
$column_two_width: 200px;
$column_three_width: 350px;

.GamePlaceWrap{
    .GamePlaceBx{
        position: fixed;
        bottom: 70px;
        // padding-bottom: 10px;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 10;
    }
    .expandBtn{
        display: block;
        position: absolute;
        right: 16px;
        top: -40px;
        width: 26px;
        height: 26px;
        transform: rotate(180deg);
        background-color: #BAB0ED;
        border: none;
        box-shadow: none;
        color: #fff;
        border-radius: 5px;
    }
    .PlaceBottom{
       
        background-color: rgba(#8A82BD,.9);
        z-index: 5;
        padding:  8px 13px;
        padding-bottom: 20px;
        .totalBx{
            text-align: left;
            padding-bottom: 5px;
            max-height: 25vh;
            overflow: auto;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
        }
        .flex-bx{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
        .edit-btn{
            padding: 3px 5px;
            background-color: var(--darkblue);
            color: white;
            border-radius: 3px;
            border: none;
            font-size: 12px;

        }
        .btns{
            display: flex;
            justify-content: space-between;
            width: 100%;
            button{
                // padding: 3px 5px;
                // background-color: #fff;
                color: var(--orange);
                border: none;
                background: none;
                position: relative;
                // border-radius: 5px;
                font-size: 12px;
                // margin-right: 0.5rem;
                width: 46px;
                img{
                    width: 100%;
                }
                span{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-weight: 700;
                    font-size: 10px;
                }
            }
            button[data-active=true]{
                background-color: var(--orange);
                color: #fff;
            }
            .inputbx{
                width: 40%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input{
                    padding-left: 10px !important;
                    margin-right: 10px;
                }
                button{
                    width: 120px;
                    padding: 0;
                    height: 30px;
                    background-color: #241366;
                    border: none;
                    color: #fff;
                }
            }
        }
        .price{
          .title{
            font-size: 12px;
            // color: var(--darkblue);
            color: #fff;
            i{
                color: #F5D659;
                margin-left: 3px;
                font-style:normal;
            }
          }  
          input{
            width: 100px;
            height: 26px;
            border-radius: 8px;
            border: 1px solid var(--midblue);
            margin-right: 5px;
          }
        }
        .inputWrap{
            i{
                font-size: 12px;
                color: var(--darkblue);
                font-style:normal;
                color: #fff;
            }
            color: #F5D659;
        }
        .btn-bx{
            .clear-btn{
                width: 55px;
                padding: 5px 5px;
                background-color: #BAB0ED;
                color: #132766;
                border-radius: 3px;
                border: none;
                margin-right: 7px;
            }
            .send-btn{
                width: 80px;
                padding: 5px 5px;
                background-color: #241366;
                color: #fff;
                border-radius: 3px;
                border: none;
            }
        }
    }
}

.trendTable{
    position: relative;
    height: 199px;
    &.fixed_headers {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        overflow-x: auto;
        margin: 0 auto;
        th { text-decoration: underline; }
        th, td {
            padding: 5px;
            text-align: center;
            text-align: center;
            i{
                width: 20px;
                text-align: center;
                display: inline-block;
                margin-right: 3px;
            }
        }
        tr{
            width: 100%;
            display: flex;
        }
        td:nth-child(1),th:nth-child(1){
            min-width: 55px;
            width: 18%;
        }
        td:nth-child(2),th:nth-child(2){
            min-width: 143px;
            width: 33%;
        }
        td:nth-child(3),th:nth-child(3){
            min-width: 72px;
            width: 16.5%;
        }
        td:nth-child(4),th:nth-child(4){
            min-width: 202px;
            width: 20.25%;
        }
        td:nth-child(5),th:nth-child(5){
            min-width: 250px;
            width: 20.25%;
        }
        // td:nth-child(1), th:nth-child(1) { min-width: $column_one_width; }
        // td:nth-child(2), th:nth-child(2) { min-width: $column_two_width; }
        // td:nth-child(3), th:nth-child(3) { width: $column_three_width; }

        thead {
            background-color: $header_background_color;
            color: $header_text_color;
            // overflow: auto;
            tr {
            display: block;
            position: relative;
            width: 100%;
            overflow-x: auto;
            }
        }
        tbody {
        display: block;
        overflow: auto;
        width: 100%;
        white-space: nowrap;
        height: $table_body_height;
            tr{
                background: rgba(138, 130, 189, 1);
                // background: var(--btnblue);
                color: #fff;
            }
            tr:nth-child(even) {
                td{
                    background-color: $alternate_row_background_color;
                    color: #000;
                }
            
            }
            tr:first-child{
                td{
                    background-color: #241366;
                    color: #fff;
                    text-align: center;
                }
               
            }
        }
    }

    &.old_ie_wrapper {
    position: relative;
    height: $table_body_height;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    tbody { height: auto; }
    }
}
.ruleWrap{
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    height: 0;
    z-index: 100;
    width: 100vw;
    max-width: 450px;
    height: 100vh;
    overflow: auto;
    padding: 0 10px;
    .ruleDes{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // background: #fff;
        width: 70%;
        // max-width: 320px;
        height: 70vh;
        padding: 10px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 10px;
        background: rgba(0,0,0,.7);
        a{
            color: #fff;
            width: 80%;
            background: linear-gradient(90deg, rgba(161,128,222,1) 0%, rgba(100,46,196,1) 100%);
            text-align: center;
            align-items: center;
            padding: 7px 15px;
            margin-bottom: 10px;
            border-radius: 10px;
            box-shadow:  0px 5px 11px 2px rgba(100,46,196,0.5);
            margin-left: auto;
            margin-right: auto;
            &:last-child{
                margin-bottom: 0;
            }
            img{
                width: 50px;
                margin-right: 10px;
            }
        }
    }
    .ruleClose{
        position: absolute;
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
        background: var(--darkblue);
        border-radius: 50%;
        color: #fff;
        svg{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .ruleScrollBx{
        overflow: auto;
        width: 100%;
        height: 100%;
    }
}


.sweet-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}


.trendTable.fixed_headers.markSixTrend table{

    width: 100%;
    tbody tr{
        td{
            width: auto !important;
            // &:nth-child(2){
            //     width: 30%;
            // }  
            &:nth-child(3){
                min-width: 151px;
            }
            &:nth-child(4){
                min-width: 101px;
            }
            &:nth-child(5){
                min-width: 300px;
            }
            &:nth-child(6){
                min-width: 90px;
            }
            &:nth-child(7){
                min-width: 350px;
            }
            &:nth-child(8){
                min-width: 130px;
            }
        }
    }
}





.trendTable.fixed_headers tbody{
    tr:nth-child(even) td{

    }
}
.trendTable{
    height: 51vw;
    max-height: 250px;
}
.trendTable.fixed_headers th, .trendTable.fixed_headers td{
    padding: 4px;
}
.trendTable.fixed_headers td:nth-child(2), .trendTable.fixed_headers th:nth-child(2){
    display: flex;
    justify-content: center;
    min-width: auto;
    width: 37vw;
    font-size: 2.6vw;
    @media (min-width:768px) {
        font-size: 14px;
    }
    div{
        color: #fff;
        // padding: .1vw .6vw 0 .6vw;
        margin: 0 .2vw;
        text-shadow: black 0.1em 0.1em 0.1em;
        width: 3.8vw;
    }
    .T1{
        background: yellow;
    }
    .T2{
        background: blue;
    }
    .T3{
        background: gray;
    }
    .T4{
        background: orange;
    }
    .T5{
        background: turquoise;
    }
    .T6{
        background: palevioletred;
    }
    .T7{
        background: gainsboro;
    }
    .T8{
        background: red;
    }
    .T9{
        background: darkred;
    }
    .T10{
        background: green;
    }
    .blue{
        background: blue;
    }
    .green{
        background: green;
    }
    .red{
        background: red;
    }
}
.trendTable.fixed_headers td:nth-child(1), .trendTable.fixed_headers th:nth-child(1){
    min-width: auto;
    width: 12vw;
    font-size: 2.6vw;
    @media (min-width:600px) {
        font-size: 14px !important;
    }
}
.trendTable.fixed_headers td:nth-child(3), .trendTable.fixed_headers th:nth-child(3){
    min-width: auto;
    width: 13vw;
    font-size: 2.6vw;
    @media (min-width:600px) {
        font-size: 14px !important;
    }
}
.trendTable.fixed_headers td:nth-child(4), .trendTable.fixed_headers th:nth-child(4){
    min-width: auto;
    width: 20vw;
    font-size: 2.6vw;
    word-break: break-all;
    white-space: initial;
    @media (min-width:600px) {
        font-size: 14px !important;
    }
    div{
        color: #fff;
        // padding: .1vw .6vw 0 .6vw;
        margin: 0 .2vw;
        text-shadow: black 0.1em 0.1em 0.1em;
        width: 3.8vw;
    }
    .blue{
        background: blue;
    }
    .green{
        background: green;
    }
    .red{
        background: red;
    }
}
.trendTable.fixed_headers td:nth-child(5), .trendTable.fixed_headers th:nth-child(5){
    min-width: auto;
    width: 18vw;
    font-size: 2.6vw;
    word-break: break-all;
    white-space: initial;
    @media (min-width:600px) {
        font-size: 14px !important;
    }
}
.trendTable.fixed_headers th i, .trendTable.fixed_headers td i{
    width: auto;
    margin-right: 0;
}
.trendTable.fixed_headers tbody tr{
    align-items: center;
    &:first-child{
        background-color: #241366 !important;
    }
    &:nth-child(odd){
        background: #fff;
        color: #000;
    }
    &:nth-child(even){
        background: rgb(138, 130, 189);
        td{
            background: rgb(138, 130, 189);
        }
    }
}
.trendTable.fixed_headers th i, .trendTable.fixed_headers td i{
    font-style: normal;
    font-weight: 500;
    &.TBlue{
        color: blue;
    }
    &.TRed{
        color: red;
    }
}


.trendTable.fixed_headers.markSixTrend table tbody tr td:nth-child(1){
    min-width: auto;
    width: 10vw !important;
}
.trendTable.fixed_headers.markSixTrend table tbody tr td:nth-child(2){
    min-width: auto;
    width: 30vw !important;
}
.trendTable.fixed_headers.markSixTrend table tbody tr td:nth-child(3){
    min-width: auto;
    width: 30vw !important;
}

.trendTable.fixed_headers.markSixTrend table tbody tr td:nth-child(4){
    min-width: auto;
    width: 30vw !important;
}

.trendTable.fixed_headers.fourBallTrend table tbody tr td:nth-child(1){
    min-width: auto;
    width: 20vw !important;
}
.trendTable.fixed_headers.fourBallTrend table tbody tr td:nth-child(2){
    min-width: auto;
    width: 40vw !important;
}
.trendTable.fixed_headers.fourBallTrend table tbody tr td:nth-child(3){
    min-width: auto;
    width: 40vw !important;
}



.trendTable.fixed_headers.kenoTrend table tbody tr td:nth-child(2){     
    word-break: break-all;
    white-space: initial;
}
.trendTable.fixed_headers.kenoTrend table tbody tr td:nth-child(1){     
    width: 10vw;
  }
.trendTable.fixed_headers.kenoTrend table tbody tr td:nth-child(3){     
    width: 10vw;
  }
  .trendTable.fixed_headers.kenoTrend table tbody tr td:nth-child(4){     
    width: 20vw;
  }
.trendTable.fixed_headers.kenoTrend table tbody tr td:nth-child(5){     
  width: 23vw;
}




.qucikClearBx{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    >div{
        width: 30%;
        text-align: center;
        color: #fff;
        border: 1px solid #fff;
        padding: 10px 3px;
        box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF;
        border-radius: 4px;
    }
    >div[data-active=true]{
        background: #fff;
        color: var(--darkblue);
        box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 15px -2px #FFFFFF;
    }
}

.normalFastinput{
    width: 80px !important;
    padding-left: 0 !important;
    text-align: center !important;
    height: 30px !important;
    border-radius: 3px !important;
    margin-bottom: 5px !important;
}

.GamePlaceWrap .PlaceBottom .btns button.close{
    background: #6c757d;
    pointer-events: none;
}

.normalFastShowBtn{
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -53px;
    width: auto;
    // height: 26px;
    background-color: #BAB0ED;
    border: none;
    box-shadow: none;
    color: #fff;
    border-radius: 5px;
    padding: 8px;
    line-height: 1.1;
}

.gamePage .gameWrap .itemWrap .circleWrap .name.close{
    background: #6c757d;
    pointer-events: none;
}
.gamePage .gameWrap .itemWrap .circleWrap .close.name[data-active=true]{
    background: #fff;
}

.gamePage.vietnamLotteryPage .gameWrap .itemWrap .circleWrap .close.name[data-active=true]{
    color: #4E90CC;
}