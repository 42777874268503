* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  // border: 1px solid rgb(174, 174, 174);
}
.app {
  background-color: var(--background);
}
.container {
  min-height: 100vh;
}
body{
  max-width: 450px;
  width: 100%;
  margin: 0 auto !important;
  background: #000 !important;
  // background: url('../images/pcBg.jpg') no-repeat center / cover !important;
}
.page {
  // padding-top: calc(72px + 64px);
  // min-height: 100vh;
  background-color: var(--background);
}
.page{
  margin-top: calc(38.5vw + 108px);
  @media (min-width:450px) {
    margin-top: calc(38.5vw + 108px);
  }
  @media (min-width:767px) {
    margin-top: 285px;
  }
  &.mt-0{
    margin-top: 0;
  }
}
.main * {
  
  // border: 1px solid rgb(174, 174, 174);
}

// 手機版
@media (max-width: 768px) {
  .page {
    padding-top: 0px;
  }
}

