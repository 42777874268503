.gamePage {
  header {
    width: 100%;
    background-color: white;
    height: 45px;
    justify-content: space-between;
    padding: 0 0.5rem;
    .left {
      display: flex;
      align-items: center;
      .backBtn{
        width: 30px;
        height: 30px;
        margin-bottom: 6px;
        color: var(--btnblue);
      }
      .title {
        // font-size: 1rem;
        color: var(--btnblue);
        line-height: 1;
        font-size: 14px;
      }
      span {
        font-size: 11px;
        margin: auto;
      }
    }
    .right {
      display: flex;
      justify-content: start;
      align-items: center;
      button {
        padding: 6px 10px;
        background-color: rgba(138, 130, 189, 1);
        color: white;
        border-radius: 5px;
        border: none;
        font-size: 12px;
        margin-left: 0.5rem;
      }
    }
  }
  .gameModel{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.2rem 0.8rem;
    // margin-top: 6px;
    li{
      width: 23.7%;
      text-align: center;
      // margin: 0 3px;
      margin-bottom: 6px;
      // padding: 4px 0;
      // background: #fff;
      img:last-child{
        display: none;
      }
    }
    li[data-active=true]{
      // background: var(--darkblue);
      // color: #fff;
      img:first-child{
        display: none;
      }
      img:last-child{
        display: block;
      }
    }
  }
  // vedio
  .vedioWrap {
    width: 100%;
    // height: 43.5%;
    background-color: black;
    position: relative;
    padding-bottom: 51%;
    .announcement {
      background-color: #332b3a;
      color: white;
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 12px;
        margin-right: 0.25rem;
      }
    }
    iframe{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      body{
        margin: 0;
      }
    }
  }
  .marquee-container{
    position: relative;
    // top: -10px;
  }
  // 會員資料、金額
  .memberWrap {
    position: relative;
    // top: -10px;
  }
  // 投注區
  .gameWrap {
    padding: 0.5rem;
    padding-bottom: 80px;
    // position: relative;
    // z-index: 3;
    .btnsWrap {
      flex-direction: column;
      min-width: 100px;
      width: 100px;
      margin-right: 15px;

      &.active{
        position: fixed;
        height: 45vh;
        overflow: auto;
        @media (max-height : 780px) {
          height: 35vh;
        }
      }
      li {
        // padding: 0.5rem;
        text-align: center;
        color: #fff;
        // background-color: white;
        // border-bottom: 1px solid var(--darkblue);
        &.active{
          // background-color: var(--darkblue);
          // color: #fff;
        }
        img{
          margin: 0 auto;
          position: relative;
          left: -2px;
        }
        img:last-child{
          display: none;
        }
      }
      li[data-active=true]{
        background-color: var(--darkblue);
        color: #fff;
       
      }
      li.active{
        img:first-child{
          display: none;
        }
        img:last-child{
          display: block;
        }
      }
    }
    .model1.itemWrap{
      width: calc(100% - (115px));
    }
    .itemWrap {
      
      width: 100%;
      margin-left: auto;
      padding-bottom: 60px;
      h5 {
        width: 100%;
        // background-color: white;
        font-size: 16px;
        padding: 5px;
        text-align: center;
        color: #DAE3FF;
        font-weight: 500;
        position: relative;
        margin-bottom: 18px;
        &::before, &::after{
          position: absolute;
          content: '';
          top: 50%;
          transform: translateY(-50%);
          background: rgba(#DAE3FF , .5);
          height: 1px;
          width: 25%;
        }
        &::before{
          left: 0;
        }
        &::after{
          right: 0;
        }
        &.long{
          &::before, &::after{
            width: 15%;
          }
        }
      }
      .circleCard{
        position: relative;
        .cardanchor{
          position: absolute;
          top: -36px;
        }
      }
      .circleWrap {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        .name {
          // width: 48px;
          // height: 48px;
          width: 18%;
          height: 0px;
          padding-bottom: 17.3%;
          border-radius: 100px;
          margin-bottom: 0.5rem;
          line-height: 16px;
          text-align: center;
          font-size: 14px;
          // background: var(--linear);
          background: none;
          box-shadow: inset 0px 0px 0px 0px #ABABAB,0px 0px 7px -2px #FFFFFF;
          border: 1px solid #fff;
          font-weight: 400;
          color: white;
          position: relative;
          i{
            font-style: normal;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            text-align: center;
          }
        }
        .name[data-active=true]{
          background: #fff;
          color: var(--darkblue);
          box-shadow: inset 0px 0px 0px 0px #ABABAB,0px 0px 15px -2px #FFFFFF;
        }
        .name[data-nclick=true]{
          background: #6c757d;
          pointer-events: none;
        }
        span{
          position: absolute;
          font-size: 10px;
          top: 80%;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translate(-50%,-50%) scale(.7);
          line-height: 0;
        }
      }
      .fourCircle.circleWrap{
        .name {
          width: 23%;
          padding-bottom: 22.23%;
        }
      }
      &.sumWrap{
        .circleWrap{
          justify-content: flex-start;
        }
        .circleBx{
          width: calc((100% - 20px) / 5);
          // width: 18%;
          &:not(:nth-child(5n)){
            margin-right: 5px;
          }
          .name{
            width: 100%;
            padding-bottom: 98%;
          }
        }
        .name{
          margin-left: auto;
          margin-right: auto;
        }
        .smallText{
          font-size: 15px;
        }
      }
    }
    
  }
  .home-bg2{
    position: relative;
    // top: -10px;
    padding-top: 10px;
  }
  .gameBotBx{
    position: relative;
   
    // padding-top: 16px;
    // top: -10px;
  }
}

.vietnamLotteryPage.gamePage{
  .home-bg2{
   
    padding-top: 0px;
  }
  .gameBotBx{
    margin-top: 152px !important;
  }
}


.gamePage .gameModel li{
  height: 33px;
  background: rgba(255, 255, 255, 0.57);
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.2;
  word-break: break-all;
  &[data-active=true]{
    background: rgba(196, 185, 255, 0.65);
  }
  >div{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.gamePage .gameWrap .btnsWrap li{
  margin-bottom: 10px;
}
.gamePage .gameWrap .btnsWrap li.active{
  color: #B5D5FF;
}
.gamePage .gameWrap .btnsWrap li img{
  width: 22px;
  margin-bottom: 5px;
}
.gamePage .gameWrap .btnsWrap li:nth-child(2) img{
  width: 30px;
}
.gamePage .gameWrap .btnsWrap li.changModelBtn{
  height: 33px;
  width: 88%;
  margin: 0 auto;
  margin-bottom: 15px;
  background: rgba(#EFEEFF, 0.4);
  color: #272C3D;
  border-radius: 5px;
  font-size: 16px;
  line-height: 2;
  
  &.yellow{
    background: rgba(#FFF0B1, 0.8);
    color: #4F3A21;
  }
}

.gamePage .markSixWrap.gameWrap .itemWrap .circleWrap{
  justify-content: flex-start;
  .name{
    &:not(:nth-child(5n)){
      margin-right: 2.5%
    }
  }
}



.gamePage.vietnamLotteryPage{
  .fixBx .right{
    select{
      height: 30px !important;
      border: none !important;
      padding-left: 0rem !important;
      text-align: center;
    }
  }
  .gamePage .gameBotBx{
    margin-top: 152px !important;
  }
 
  .vedioWrap{
    // background: none ;
    padding-bottom: 0%;
    margin-bottom: 15px;
    padding-top: 3%;
  }
  .nextDrawBx{
    display: flex;
    background: #4E90CC;
    div{
      width: 50%;
      font-size: 13px;
      text-align: center;
      color: #fff;
    }
  }
  .gameDrawWrap{
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    .nextDrawBx{
      display: flex;
      div{
        width: 50%;
        font-size: 13px;
        text-align: center;
        color: #fff;
      }
    }
    .flex-bx{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:  0 10px;
      color: #fff;
      margin-bottom: 5px;
      position: relative;
      .text{
        width: 70%;
        text-align: center;
        margin: 0 auto;
      }
      button{
        width: 30px;
        height: 30px;
        // position: relative;
        background: none;
        border: none;
      }
      .prevDraw{
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        &::before{
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 16px;
          height: 16px;
          clip-path: polygon(100% 0, 0 50%, 100% 100%);
          background: #fff;
        }
      }
      .nextDraw{
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        &::before{
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 16px;
          height: 16px;
          clip-path: polygon(0 0, 100% 50%, 0 100%);          background: #fff;
        }
      }
    }
    table{
      color: #000;
      // width: 96%;
      // margin: 0 auto;
      letter-spacing: 0px;
      font-size: 12px;
      line-height: 16px;
      tr{
        background: #fff;
        border: 1px solid #fff;
        vertical-align: middle;
        &:nth-child(even){
          background: rgb(238, 238, 238);
        }
        td{
          text-align: center;
       
          // padding: 2px;
          >div{
            display: inline-block;
          }
        }
        td:nth-child(1){
          // width: 25vw;
          min-width: 80px;
        }
        td:nth-child(2){
          // width: 75vw;
          // width: calc(100% - 80px);
          width: 100%;
          padding: 0 5px;
          vertical-align: middle;
        }
        span{
          color: rgb(16, 110, 182);
          font-weight: 900;
        }
        span[data-active=true]{
          background: rgb(16, 110, 182);
          color: #fff;
          padding: 3px;
          // margin: 1px;
          border-radius: 50%;
        }
        i{
          font-style: normal;
        }
        i[data-active=true]{
          background: rgb(16, 110, 182);
          color: #fff;
          padding: 3px;
          // margin: 1px;
          border-radius: 50%;
        }
      }
    }
    .flex{
      display: flex;
      table{
        width: 60%;
      }
      .drawIntegrateBx{
        width: 40%;
        background-color : rgb(185, 214, 245);
        color: #000;
        word-break: break-all;
        >div{
          display: flex;
          border-bottom: 1px solid #fff;
          &:nth-child(even){
            background-color :rgb(134, 187, 242)
          }
          div{
            &:first-child{
              min-width: 20px;
              text-align: center;
              border-right: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:last-child{
              width: calc(100% - 20px);
              text-align: center;
            }
          }
        }
        // background-color : rgb(134, 187, 242);
      }
    }
  }
}

.gamePage header .right .messageBtn{
  padding: 5px 7px;
  svg{
    width: 20px;
    height: 20px;
  }
}

.chatWrap{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  width: 90vw;
  height: 76vh;
  max-width: 400px;
  .CloseBtn{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    right: 0px;
    background-color: rgb(138, 130, 189);
    box-shadow: none;
    border: none;
    svg{
      color: #fff;
    }
  }
  .BanListBtn{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    right: 50px;
    background-color: rgb(138, 130, 189);
    box-shadow: none;
    border: none;
    svg{
      color: #fff;
    }
  }
  .chatBx{
    padding: 5px;
    position: relative;
    height: 100%;
  }
  .chatPaperBx{
    box-shadow: none;
    position: relative;
    overflow-y: auto;
    height: calc(100% - 47px);
  }
  .MesgbottomInputWrap{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    >div:first-child{
      width: calc(100% - 70px);
      label{
        line-height: 14px;
      }
      input{
        padding-left: 10px !important;
      }
    }
    button{

    }
  }
  .messageRow{
    display: flex;
    .messageRbx{
      margin-left: 5px;
    }
    .messageBlue{
      position: relative;
      margin-left: 20px;
      margin-Bottom: 10px;
      padding: 10px;
      background-color: #A8DDFD;
      // width: 60%;
      min-width: 100px;
      //height: "50px";
      text-align: left;
      // font: "400 .9em 'Open Sans'; sans-serif";
      border: 1px solid #97C6E3;
      border-radius: 10px;
      word-break: break-all;
      &:after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 15px solid #A8DDFD;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 0;
        left: -15px
      }
      &:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 17px solid #97C6E3;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        top: -1px;
        left: -17px
      }

      .deleteButton{
        position: absolute;
        left: -30px;
        bottom: 2px;
        background: rgba(red , .9);
        color: #fff;
        border-radius: 50%;
        box-shadow: none;
        border: none;
        width: 24px;
        height: 24px;
        svg{
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .messageTimeStampRight{
      position: absolute;
      // fontSize: .85em,
      font-weight: 300;
      margin-top: 10px;
      bottom: -3px;
      right: 5px;
    }
  }
  .messageRowRight{
    display: flex;
    justify-content: flex-end;
    .messageOrange{
      position: relative;
      margin-right: 35px;
      margin-bottom: 10px;
      padding: 10px;
      background-color: #f8e896;
      width: 60%;
      //height: 50px;
      text-align: left;
      // font: 400 .9em 'Open Sans'; sans-serif;
      border: 1px solid #dfd087;
      border-radius: 10px;
      word-break: break-all;
      &:after{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 15px solid #f8e896;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: 0;
        right: -15px
      }
      &:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 17px solid #dfd087;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        top: -1px;
        right: -17px
      }
      .deleteButton{
        position: absolute;
        right: -30px;
        bottom: 2px;
        background: rgba(red , .9);
        color: #fff;
        border-radius: 50%;
        box-shadow: none;
        border: none;
        width: 24px;
        height: 24px;
        svg{
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
    .messageContent{
      padding: 0;
      margin: 0;
      margin-bottom: 1em;
    
    }
    .messageTimeStampRight{
      position: absolute;
      // font-size: .85em;
      font-weight: 300;
      margin-top: 10px;
      bottom: -3px;
      right: 5px
    }
  }
}
.BanListWrap{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1000;
  width: 80vw;
  height: 60vh;
  background: #fff;
  padding: 10px;
  border: 1px solid #000;
  .title{
    text-align: center;
    font-size: 20px;
   
  }
  .banList{
    height: 94%;
    overflow-y: auto;
    >div{
      padding: 3px 0;
      font-size: 16px;
    }
  }
  .CloseBtn{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -20px;
    right: 0px;
    background-color: rgb(138, 130, 189);
    box-shadow: none;
    border: none;
    svg{
      color: #fff;
    }
  }
}

.ruleWrap .vietOpenDes{
  width: 88% !important;
}

.openListTitle{
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center;
}
.vietOpenList{
  
  .flexBx{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    >div{
      text-align: center;
      span{
        &.green{
          color: green;
        }
        &.red{
          color: red;
        }
      }
      &:nth-child(1){
        width: 40%;
        text-align: left;
      }
      &:nth-child(2){
        width: 25%;
      }
      &:nth-child(3){
        width: 35%;
      }
    }
  }
}



$newColor : #F7D597;
$grayColor : #88929c;
.vietnamLotteryPage.gamePage{
  
  header .left .backBtn{
    svg{
      color: #fff;
    }
  }
  header .left .title{
    color: #fff;
  }
  header{
    background: $newColor;
    height: 44px;
  }
  header .right button,.memberWrap .right button{
    background: $newColor;
    border: 1px solid #fff;
  }
  .memberWrap{
    border-bottom: 1px solid $newColor;
  }
  .memberWrap .left,.memberWrap .right{
    color: $newColor;
   
  }
  .gameModel li[data-active=true]{
    background: $newColor;
  }
  .gameWrap .itemWrap .circleWrap .name[data-active=true]{
    color: $newColor;
  }
  .vedioWrap{
    background: $newColor;
  }
  .gameDrawWrap .flex-bx{
    background: $grayColor;
    margin-bottom: 0;
  }
  .marquee-container {
    background: $grayColor;
  }
  .home-bg2{
    background: #fff;
  }
  .gameModel li{
    border: 1px solid $newColor;
    color: $newColor;
  }
  .gameModel li[data-active=true]{
    color: #fff;
  }
  .basicBx>button{
    border: 1px solid $newColor;
    color: $newColor;
  }
  .itemWrap h5{
    color: $newColor;
  }
  .gameWrap .itemWrap h5::before, .gameWrap .itemWrap h5::after{
    background: $newColor;
  }
  .gameWrap .itemWrap .circleWrap .name{
    border: 1px solid $newColor;
    color: $newColor;
  }
  .gameWrap .itemWrap .circleWrap .name[data-active=true]{
    background: $newColor;
    color: #fff;
    box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 15px -2px $newColor;
  }
  .qucikClearBx > div{
    color: $newColor;
    border: 1px solid $newColor;
  }
  .qucikClearBx > div[data-active=true]{
    background: $newColor;
    color: #fff;
    box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 15px -2px $newColor;
  }
  .gameWrap{
    padding-bottom: 110px;
  }
  .gameWrap .itemWrap{
    padding-bottom: 40px;
  }
}
.vietGamePlace.GamePlaceWrap{
  .expandBtn{
    background: $newColor;
  }
  .PlaceBottom{
    background: rgba($newColor , .9);
  }
  .PlaceBottom .btn-bx .clear-btn{
    // background: $newColor;
    color: #6c757d;
    background-color: rgb(185, 214, 245);
   
  }
  .PlaceBottom .btn-bx .send-btn{
    background: $newColor;
    background-color: rgb(134, 187, 242);
  }
 
}
.chatWrap .BanListBtn,.chatWrap .CloseBtn{
  background-color: $newColor;
}
.BanListWrap .CloseBtn{
  background-color: $newColor;
}
.ruleWrap .ruleClose{
  background: $newColor !important;
}
.GamePlaceBx .normalFastShowBtn{
  background-color: $newColor;
}


.bingoPage{
  .home-bg2{
    background: #0A4529;
  }
  .gameWrap .model1.itemWrap{
    width: 100%;
    .circleCard[data-active=false]{
      display: none;
    }
  }
  &.gamePage .gameWrap .itemWrap .circleWrap{
    justify-content: flex-start;
   
  }
  &.gamePage .gameWrap .itemWrap .fourCircle.circleWrap .name:not(:nth-child(4n)){
    margin-right: 2.666%;
  }
  &.gamePage .gameWrap .itemWrap .circleWrap .name{
    background: linear-gradient(180deg,#15603F, #3C9F70);
    border: none;
    box-shadow: none;
    border-radius: 10px;
    padding-bottom: 17.23%;
    color: #ffed00;
    i{
      top: 40%;
    }
    span{
      color: #fff;
      font-size: 14px;
      top: 75%;
    }
  }
  &.gamePage .gameWrap .itemWrap .circleWrap .name[data-active=true]{
    // background: none !important;
    background: linear-gradient(to top, #e3bf76, #f6e2a4 , #e3bf76) !important;  
    color: #0A4529;
  }
  
  &.gamePage .gameWrap .itemWrap .b18.circleWrap .name{
    background: linear-gradient(to top, #e3bf76, #f6e2a4 , #e3bf76) !important;  
    color: #0A4529;
    font-size: 30px;
    font-weight: 700;
    span{
      color: #0A4529;
      top: 80%;
    }
  }
  &.gamePage .gameWrap .itemWrap .b18.circleWrap .name[data-active=true]{
    background: linear-gradient(180deg,#15603F, #3C9F70) !important;
    color: #ffed00;
    span{
      color: #ffed00;
    }
  }

  &.gamePage .gameWrap .itemWrap .b18sp.circleWrap{
    justify-content: space-between;
   
  }
  &.gamePage .gameWrap .itemWrap .b18sp.circleWrap .name , &.gamePage .gameWrap .itemWrap .b18sp.circleWrap .name{
    // background: none !important;
    width: 32.5%;
    padding-bottom: 47%;
    margin-right: 0 !important;
    i{
      top: 20px;
    }
    span{
      top: 85%;
    }
    &::before{
      position: absolute;
      display: block;
      content: '';
      width: 60%;
      height: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
     
    }
    &:nth-child(1){
      position: relative;
      &::before{
        background: url(../../images/bingo18/3ballTriple.png) no-repeat center / contain;
      }
    }
    &:nth-child(2){
      position: relative;
      &::before{
        background: url(../../images/bingo18/ballStraight.png) no-repeat center / contain;
      }
    }
    &:nth-child(3){
      position: relative;
      &::before{
        background: url(../../images/bingo18/ballOnePair.png) no-repeat center / contain;
      }
    }
   
  }

  &.gamePage .gameWrap .itemWrap .b18B.circleWrap{
    justify-content: space-between;
  }
  &.gamePage .gameWrap .itemWrap .b18B.circleWrap .name{
    // background: none !important;
    width: 32.5%;
    padding-bottom: 30%;
    margin-right: 0 !important;
    position: relative;
    i{
      top: 20px;
      opacity: 0;
    }
    span{
      top: 85%;
    }
    &::before{
      position: absolute;
      display: block;
      content: '';
      width: 60%;
      height: 60%;
      top: 45%;
      left: 50%;
      transform: translate(-50%,-50%);
     
    }
    &:nth-child(1){
      
      &::before{
        background: url(../../images/bingo18/3ball-1.png) no-repeat center / contain;
      }
    }
    &:nth-child(2){
      &::before{
        background: url(../../images/bingo18/3ball-2.png) no-repeat center / contain;
      }
    }
    &:nth-child(3){
      &::before{
        background: url(../../images/bingo18/3ball-3.png) no-repeat center / contain;
      }
    }
    &:nth-child(4){
      &::before{
        background: url(../../images/bingo18/3ball-4.png) no-repeat center / contain;
      }
    }
    &:nth-child(5){
      &::before{
        background: url(../../images/bingo18/3ball-5.png) no-repeat center / contain;
      }
    }
    &:nth-child(6){
      &::before{
        background: url(../../images/bingo18/3ball-6.png) no-repeat center / contain;
      }
    }
   
  }
  &.gamePage .gameWrap .itemWrap h5{
    color: #fff;
    &::before{
      width: 100%;
    }
    &::after{
      width: 100%;
    }
    span{
      position: relative;
      padding: 0 20px;
      background: #0A4529;
      z-index: 2;
    }
  }
  &.gamePage header .right button{
    background: rgba(#3C9F70, 0.9);
  }
  &.gamePage .marquee-container , &.gamePage .ruleWrap .ruleClose{
    // background: #3C9F70 !important;
    background : #2d845c !important;
  }
  &.gamePage .memberWrap .right , &.gamePage .memberWrap .left,&.gamePage header .left .backBtn , &.gamePage header .left .title{
    color: #43cea2;
  }
  &.gamePage {
    .trendTable.fixed_headers td{
      font-size: 2.6vw;
      @media (min-width:600px) {
        font-size: 12px !important;
    }
    }
    .trendTable.fixed_headers table{
      width: 100%;
      display: block;
    }
    
    .trendTable.fixed_headers td i{
      margin-left: 5px;
    }
    .trendTable.fixed_headers tbody tr:first-child,.trendTable.fixed_headers tbody tr:first-child td{
      background: #0A4529 !important;
    }
    .trendTable.fixed_headers tbody tr:nth-child(even),.trendTable.fixed_headers tbody tr:nth-child(even) td{
      background-color: rgba(#3C9F70, 1);
    }
    .trendTable.fixed_headers td:nth-child(1){
      width: 20vw;
    }
    .trendTable.fixed_headers td:nth-child(2){
      width: 12vw;
    }
    .trendTable.fixed_headers td:nth-child(4){
      width: 13vw;
    }
    .trendTable.fixed_headers td:nth-child(5){
      width: 13vw;
    }
    .trendTable.fixed_headers td:nth-child(6){
      width: 13vw;
    }
    .trendTable.fixed_headers td:nth-child(7){
      width: 19vw;
    }
  }
  &.gamePage .vedioWrap{
    padding-bottom: 49%;
  }
}

.GamePlaceWrap.c159 .expandBtn{
  background-color: rgba(#3C9F70, 0.9);
}
.GamePlaceWrap.c159 .PlaceBottom{
  background-color: rgba(#3C9F70, 0.9);
  .btn-bx .clear-btn{
    background-color: #2d845c;
    color: #fff;
  }
  .btn-bx .send-btn{
    background-color: #0A4529;
  }
}
.GamePlaceWrap.c159 .PlaceBottom .btns .inputbx button{
  background-color: #0A4529;
}

.nkDrawBx{
  overflow: hidden;
  // border: solid 2px #ffd486;
  .topBx{
    background-color: #ffd486;
    padding: 5px 10px;
    text-align: center;
    color: #8a1c08;
    font-weight: 700;
    position: relative;
    .Issue{
      span{
        color: #000;
      }
    }
    .refreshBtn{
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      box-shadow: none;
    }
  }
  .drawNumber{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    div{
      margin: 15px 10px;
      width: 55px;
      height: 55px;
      line-height: 55px;
      font-size: 32px;
      font-weight: 700;
      background-color: #f5811f;
      border: none;
      color: #FFF;
      border-radius: 50%;
      box-shadow: 0 0 0 3px #ffcea5;
      text-align: center;
    }
  }
  .drawSum{
    display: flex;
    border-top: solid 2px #ededed;
    div{
      width: 33.3333%;
      text-align: center;
      background: #fff;
      font-weight: 700;
      color: #f0572c;
      font-size: 15px;
      padding: 7px 0;
      &:not(:last-child){
        border-right: solid 2px #ededed;
      }
    }
  }
}