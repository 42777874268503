.profilePage{
  min-height: 100vh;
}
.userInfoWrap {
  // background: #f9faff;
  height: 120px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  // box-shadow: rgba(0, 0, 0, 0.03) 0px 4px 22px;
  height: auto;
  flex-wrap: wrap;
  margin-top: 0 !important;
  @media (max-width:768px) {
    height: auto;
    flex-wrap: wrap;
  }
  svg {
    font-size: 3.5rem;
  }
 
  .buttonsWrap {
    width: 60%;
    justify-content: space-between;
    display: flex;
    // background: rgba(#fff,.2);
    border-radius: 10px;
    margin-top: 8px;
    position: relative;
    width: 100%;
    &::before{
      position: absolute;
      content: '';
      left: -16px;
      top: -20px;
      width: 100vw;
      height: 1px;
      background: rgba(#fff,.4);
      display: none;
    }
    button {
      border: none;
      background-color: transparent;
      flex-direction: column;
      align-items: center;
      width: 32%;
      background: #2A2930;
      border-radius: 6px;
      padding: 12px 3px;
      svg {
        color: var(--linear);
      }
      span {
        margin-top: 0.5rem;
        font-size: 1.2rem;
        color: var(--darkblue);
      }
    }
    @media (max-width:768px) {
      width: 100%;
    }
  }

  .logoutBtn{
    
  }

  .avater-bx{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background: #2A2930;
    padding: 15px;
    border-radius: 6px;
    .avatar{
      width: 80px;
    }
    .r{
      width: calc(100% - 100px);
      border-radius: 10px;
      font-weight: 700;
      position: relative;
    }
  }
  .nameBx{
    color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 14px !important;
    &:last-child{
      padding-right: 65px;
    }
    >p:first-child{
      max-width: 90px;
    }
    span{
      margin-left: 8px;
    }
    .number span{
      margin-left: 0;
    }
    button{
      background: none;
      border: none;
      display: flex;
      align-items: center;
      color: #959498;
      font-weight: 700;
      border-radius: 4px;
      border: 1px solid #959498;
      padding: 3px 12px;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #2A2930;
      img{
        width: 30px;
        margin-right: 5px;
        display: none;
      }
    }
    .green{
      color: green;
    }
    .red{
      color: #D83030;
    }
  }
  .moneyInfo {
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid #fff;
    border-radius: 10px;
    flex-wrap: wrap;
    box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF;
    div {
      // padding: 0.8rem 1.5rem;
      p {
        letter-spacing: 1px;
        color: #fff;
        font-size: 12px;
      }
      .number {
        color: #E2EFFF;
        font-size: 14px;
      }
    }
    .balance {
      border-right: 1px solid #d3d6e2;
      padding-right: 15px;
      margin-right: 10px;
    }
    button {
      width: 48px;
      height: 32px;
      font-size: 1rem;
      border-radius: 4px;
      background: rgba(#403294,.6);
      color: white;
      border: none;
      font-size: 14px;
      margin-left: auto;
    }
    
  }
}

// Profile btns
.profileBtns {
  padding: 0 16px;
  margin-top: 15px !important;
  .itemBtnBx{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .itemBtn {
    cursor: pointer;
    width: 32%;
    text-align: center;
    margin-bottom: 8px;
    background: #2A2930;
    border-radius: 6px;
    padding: 12px 3px;
    &:not(:nth-child(3n)){
      margin-right: 2%;
    }
    img{
      margin: 0 auto;
    }
  }
  .itemBtnBx.agentItemBx{
    display: block;
    background: #2D2D36;
    border-radius: 6px;
    padding: 17px;
    padding-bottom: 9px;
    border: 1px solid rgba(#FFFFFF , .2);
    .itemBtn{
      border-radius: 0px;
      width: 100%;
      text-align: left;
      padding : 3px;
      margin: 0;
      display: block;
      padding-bottom: 8px;
      margin-bottom: 8px;
      background: none;
      &:not(:last-child){
        border-bottom: 1px solid rgba(#FFFFFF , .2);
      }
      .imgBx{
        display: none;
      }
      .ti{
        margin-top: 0;
        position: relative;
        &::before{
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          border-top: 3px solid #fff;
          border-right: 3px solid #fff;
          top: 50%;
          right: 5px;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}
.bottWrap{
  margin: 0 auto;
  width: calc(100% - 32px);
  margin-top: 10px;
  margin-bottom: 20px;
}
.bettingRecordWrap{
  color: #fff;
  .listBx{

    min-height: 250px;
    margin-bottom: 10px;
  }
  .titileBx{
    display: flex;
    min-width: 350px;
    div{
      width: 12%;
      font-size: 12px;
      text-align: center;

      color: #E2EFFF;
      // background: #fff;
      padding: 3px 0 1px 0;
      border-bottom: 1px solid var(--background);
      font-weight: 600;
      &:not(:last-child){
        border-right: 1px solid var(--background);
      }
      &:nth-child(1){
        width: 22%;
        text-align: center;
      }
      &:nth-child(2){
        width: 16%;
      }
      &:nth-child(3){
        width: 16%;
      }
      &:nth-child(4){
        width: 22%;
      }
    }
  }
  .listBx .scrollBx{
    min-height: 415px;
  }
  .listBx .scrollBx ul{
    padding-left: 0;
    min-width: 350px;
    li{
      display: flex;
      div{
        width: 12%;
        font-size: 12px;
        text-align: center;
        color: #E2EFFF;
        // background: #fff;
        padding: 3px 0 1px 0;
        border-bottom: 1px solid var(--background);
        &:not(:last-child){
          border-right: 1px solid var(--background);
        }

        &:nth-child(1){
          width: 22%;
        }
        &:nth-child(2){
          width: 16%;
        }
        &:nth-child(3){
          width: 16%;
          word-break: break-all;
        }
        &:nth-child(4){
          width: 22%;
        }

      }
    }
  }

  .notData{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .dataFlex{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .timeTitle{
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      min-width: 50px;
    }
    svg{
      // color: var(--darkblue);
      color: #E2EFFF;
      width: 30px;
      margin-right: 10px;
    }
    .space{
      min-width: 20px;
      text-align: center;
      display: inline-block;
    }
    .react-datepicker-wrapper{
      width: calc((100% - 20px) / 2);

      input{
        width: 100%;
        box-shadow: none;
        border: 1px solid #E2EFFF;
        color: #E2EFFF;
        background: none;
        text-align: center;
        height: 30px;
        font-size: 14px;
        padding-left: 0px !important;
      }
    }
  }
  .searchBtn{
    width: 110px;
    height: 32px;
    background: rgba(64, 50, 148, 0.6);
    box-shadow: none;
    border: none;
    color: #fff;
    border-radius: 4px;
    margin-left: auto;
    display: block;
    margin-bottom: 0px;
  }

  .sweet-loading{
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 100px;
    span{
      margin: 0 auto;
    }
  }
 
}
.listTransactionsWrap.bettingRecordWrap{
  .titileBx{
    div{
      width: 25%;
    }
  }
  .listBx .scrollBx ul{
    padding-left: 0;
    min-width: 350px;
    li{
      display: flex;
      div{
        width: 25%;
      }
    }
  }
}
.agentsWrap{
 .scrollBx{
  ul{
    padding-left: 0;
    li{
      // background: #fff;
      border: 1px solid #fff;
      border-radius: 10px;
      padding: 10px;
      color: #fff;
      box-shadow: inset 0px 0px 0px 0px #ABABAB, 0px 0px 7px -2px #FFFFFF;
      &:not(:last-child){
        margin-bottom: 15px;
      }
      div{
        display: flex;
        align-items: center;
        &:not(:last-child){
          margin-bottom: 3px;
        }
      }
      span{
        margin-right: 10px;
        min-width: 65px;
        
      }
    }
  }
 }
}
.agentRegisterWrap{
  form{
    input {
      width: 100%;
      height: 2.5rem;
      padding-left: 20px !important;
      border: 1px solid var(--bordergrey);
      font-size: 1rem;
      // margin-bottom: px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: 1rem;
        color: var(--darkblue);
        opacity: 0.5;
      }
    }
    .remaker{
      color: #E2EFFF;
      margin-bottom: 15px;
      margin-top: 5px;
    }
    .registerBtn{
      width: 100%;
      margin-top: 10px;
    }
  }
}
.phoneVerificationMsgWrap{
  form{
    input , select{
      // width: 100%;
      height: 2.5rem;
      padding-left: 20px !important;
    }
    .loginInput{
      input{
        padding-left: 40px !important;
      }
    }

    .phoneInput{
      display: flex;
      justify-content: space-between;
      select{
        padding-left: 10px !important;
        width: 40% !important;
      }
      input{
        width: 55% !important;
        padding-left: 15px !important;
      }
    }

    .registerBtn{
      width: 100%;
      margin-top: 30px;
    }
  }
}
.titleFlex{
  position: relative;
  >div{
    // color: var(--darkblue);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70%;
    text-align: center;
  }
}
.backBtn {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  font-size: 1rem;
  border-radius: 5px;
  background: none;
  // background-color: var(--darkblue);
  // color: var(--darkblue);
  color: #fff;
  border: none;
  display: block;
  text-align: left;
}



///////  0911

.notData{
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
}
.agentsWrap .selBx{
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  overflow-x: auto;
  >div{
    color: #fff;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    &:not(:last-child){
      margin-right: 10px;
    }
    &:last-child{
      border: 1px solid #48B3F2;
      color: #48B3F2;
    }
  }
}
.agentsWrap .scrollBx ul li i{
  font-style: normal;
  color: #48B3F2;
}

.agentsProfitWrap{
  .gameselBx ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    li{
      width: 33.333%;
      text-align: center;
    }
    li[data-active=true]{
      color: #48B3F2;
    }
  }
}
.searchWrap {
  input{
    padding-left: 15px !important;
    // width: 50% !important;
    width: calc((100% - 20px) / 2) !important;
  }
}
.agentsWrap .scrollBx ul li .flexBx{
  display: flex;
  &>div{
    width: 50%;
    span{
      min-width: auto;
    }
  }
}

.agentsWrap{
  .dataFlex{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .timeTitle{
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      min-width: 50px;
    }
    svg{
      // color: var(--darkblue);
      color: #E2EFFF;
      width: 30px;
      margin-right: 10px;
    }
    .space{
      min-width: 20px;
      text-align: center;
      display: inline-block;
      color: #fff;
    }
    .react-datepicker-wrapper{
      width: calc((100% - 20px) / 2);

      input{
        width: 100%;
        box-shadow: none;
        border: 1px solid #E2EFFF;
        color: #E2EFFF;
        background: none;
        text-align: center;
        height: 30px;
        font-size: 14px;
        padding-left: 0px !important;
      }
    }
  }
  .searchBtn{
    width: 110px;
    height: 32px;
    background: rgba(64, 50, 148, 0.6);
    box-shadow: none;
    border: none;
    color: #fff;
    border-radius: 4px;
    margin-left: auto;
    display: block;
    margin-bottom: 20px;
  }
  .sweet-loading{
    position: relative;
    span{
      margin: 0 auto;
    }
  }
}

.userInfoWrap .buttonsWrap button {
  .imgBx{
    width: 36px;
    height: 36px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 36px;
  }
  .ti{
    color: #fff;
    margin-top: 5px;
  }
}
.profileBtns .itemBtn{
  .imgBx{
    width: 100%;
    height: 40px;
    position: relative;
    margin: 0 auto;
  }
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30px;
  }
  .ti{
    color: #fff;
    margin-top: 5px;
  }
}
// .profileBtns .topBtnBx .itemBtn:nth-child(2){
//   img{
//     width: 40px;
//   }
// }
// .profileBtns .topBtnBx .itemBtn:nth-child(4){
//   img{
//     width: 40px;
//   }
// }



//// 0417
.profilePage.home-bg{
  // background: none;
}
.typeSel{
  width: 170px !important;
  padding-left: 0 !important;
  text-align: center;
  height: 32px;
  background: rgba(64, 50, 148, 0.6) !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff;
  border-radius: 4px;
  margin-left: auto;
  display: block;
  margin-bottom: 20px;
  margin-left: 0;
}