// 註冊表單
// .registerWrap {
//   width: 65%;
//   max-width: 750px;
//   min-width: 330px;
//   height: 550px;
//   display: flex;
//   border-radius: 10px;
//   overflow: hidden;
//   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//   z-index: 3;
//   position: relative;
//   form {
//     width: 100%;
//     height: 100%;
//     padding: 2rem 5rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     background-color: white;

//     h3 {
//       text-align: center;
//       padding-bottom: 0.5rem;
//       // width: 100px;
//       border-bottom: 3px solid var(--midblue);
//     }

//     .loginInput {
//       width: 100%;
//       position: relative;
//       input {
//         width: 100%;
//         height: 3.5rem;
//         border-radius: 100px;
//         padding-left: 4rem;
//         border: 1px solid var(--bordergrey);
//         font-size: 1.2rem;
//         &:focus {
//           outline: none;
//         }
//         &::placeholder {
//           font-size: 1.2rem;
//           color: var(--darkblue);
//           opacity: 0.5;
//         }
//       }
//       svg {
//         position: absolute;
//         font-size: 1.6rem;
//         color: var(--darkblue);
//         left: 1.5rem;
//         top: 50%;
//         transform: translateY(-55%);
//       }
//     }
//     // 註冊btn
//     input[type="submit"] {
//       width: 230px;
//       height: 60px;
//       background: var(--linear);
//       color: white;
//       font-size: 1.2rem;
//       border-radius: 0.25rem;
//       margin-top: 1rem;
//       border: none;
//     }
//     .links {
//       width: 300px;
//       display: flex;
//       justify-content: space-between;
//       margin-top: 1rem;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .registerWrap {
//     width: 95%;
//     height: 450px;

//     form {
//       padding: 1rem;

//       h3 {
//         width: auto;
//       }
//       .loginInput {
//         input {
//           height: 3rem;
//         }
//       }

//       input[type="submit"] {
//         width: 190px;
//         height: 40px;
//         margin-top: 1rem;
//         font-size: 1.1rem;
//       }
//       .links {
//         width: 100%;
//       }
//     }
//     .registerBtn {
//       width: 56px;

//       h3 {
//         font-size: 20px;
//         font-weight: 400;
//       }
//     }
//   }
// // }




.register-bx {
    .phoneInput{
        flex-wrap: wrap;
        input,select{
            padding-left: 10px !important;
        }
        select{
            width: 68% !important;
            margin-bottom: 10px !important;
        }
        input{
            width: 100% !important;
        }
    }
    .com-btn{
        padding-left: 0 !important;
        border: 1px solid #34B6FF !important;
        background: none !important;
    }
}






